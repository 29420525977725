<template>
  <div class="assets">
    <div class="item flex justify-between align-center"
      v-for="(item, index) in showCoin" :key="index"
    >
      <div class="label" :class="index % 2 == 0 ? 'label2' : ''"> {{ $t('Coin.' + item) }} </div>
      <div class="value"> {{ getAssetObj[item] }} 
        <span class="sign">{{ $t('Coin.' + item) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showCoin: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    getAssetObj() {
      let obj = {};
      let AssetsList = this.$store.state.AssetsList;
      if (AssetsList.length > 0) {
        AssetsList.forEach(item => {
          obj[item.coinName] = this.fixNum(item.assetsAvailable)
        })
      }
      return obj
    },
    showList() {
      // let list = [];
    }    
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.assets {
  margin-bottom: 30px;
  .item {
    font-size: 16px;
    .label {
      color: #2bada2;
      font-weight: bold;

      &.label2 {
        color: #764198;
      }
    }
    .value {
      font-size: 18px;

      .sign {
        font-size: 12px;
      }
    }
  }
}

</style>