import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

import {
  webUserGetAssetsList,
  webUserGetUserInfo,
  webUserGetInviteLink,
  webUserGetCountryList
} from "@/api/index" // 接口

const Web3 = require("@/common/getWeb3");
let lang = localStorage.getItem('lang') || 'zh-CN';

import WS from '@/common/socket';
import { socketUrl } from '@/http/baseUrl'; 

export default new Vuex.Store({
  state: {
    loading: false,
    lang: lang,
    AssetsList: [],
    UserInfo: {},
    pageTitle: '主页',
    inviteInfo: {},
    countryList: [],
    tokenStatus: localStorage.getItem('token'),
    socketObj: null,
    socketAll: {
      isShowSurplusTotalAmount: '-1'
    },
    socketIst: 0,
    socketSuper: 0
  },
  getters: {
  },
  mutations: {
    changeLoadingState(state, val) {
      state.loading = val;
    },
    SET_StoreMutationUserInfo(state, item) {
      state.UserInfo = item;
    },
    SET_StoreMutationAssetsList(state, assets) {
      state.AssetsList = assets;
    },
    SET_StorePageTitle(state, val) {
      state.pageTitle = val;
    },
    SET_StoreInviteLink(state, val) {
      state.inviteInfo = val;
    },
    SET_StoreCountryList(state, val) {
      state.countryList = val;
    },
    SET_StoreMutationWeb3(state, item) {
      state.web3 = item;
    },
    SET_StoreToken(state, item) {
      state.tokenStatus = item;
    },
    SET_StoreSocketObj(state, item) {
      state.socketObj = item;
      state.socketObj.onmessage = (event)=> {
        let data = JSON.parse(event);
        if(data.topic === 'all') {
          state.socketAll = data.data;
        } else if(data.topic === 'IstPool') {
          state.socketIst += 1;
        } else if(data.topic === 'SuperNode') {
          state.socketSuper += 1;
        }
      }
    },
  },
  actions: {
    async storeActionUserInfo(context) {  // 获取用户信息
      const { data } = await webUserGetUserInfo({});
      if (data.code === 20000) {
        // console.log('UserInfo :>> ', data.data);
        context.commit('SET_StoreMutationUserInfo', data.data);
      }
    },
    async storeActionAssetsList(context) {  // 获取钱包列表
      const { data } = await webUserGetAssetsList({});
      if (data.code === 20000) {
        let records = data.data.records;
        // console.log('Assets :>> ', records);
        context.commit('SET_StoreMutationAssetsList', records)
      }
    },
    async storeActionInviteLink(context) {  // 获取邀请信息
      const { data } = await webUserGetInviteLink({});
      // console.log('webUserGetInviteLink :>> ', data.data);
      if (data.code === 20000) {
        let records = data.data;
        context.commit('SET_StoreInviteLink', records)
      }
    },
    async storeActionGetCountryList(context) {  // 获取国家
      const { data } = await webUserGetCountryList({});
      if (data.code === 20000) {
        // let records = data.data;
        let lang = localStorage.getItem("lang") || "en-US";
        let list = data.data.map((item) => {
          return {
            ...item,
            en: lang === "zh-CN" ? item.name : item.en,
          };
        });
        context.commit('SET_StoreCountryList', list)
        
      }
    },
    async storeActionWeb3(context) {  // web3
      const web3 = await Web3.default.getWeb3();
      // console.log('web3 :>> ', web3);
      context.commit('SET_StoreMutationWeb3', web3)
    },
    storeSocketConnect(context) {  // 获取return wallet
      let bindId = localStorage.getItem('bindId');

      // console.log('bindId :>> ', bindId);
      let url = socketUrl;
      const WsObj = new WS(url, bindId);
      context.commit('SET_StoreSocketObj', WsObj)
    }
  },
  modules: {
    
  }
})
