import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/init.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/home.vue'),
        meta: { title: '首页' }
      },
      {
        path: '/join',
        name: 'Join',
        component: () => import('@/views/home/join.vue'),
        meta: { title: '参与计划' }
      },
      {
        path: '/wallet',
        name: 'wallet',
        component: () => import('@/views/assets/assets.vue'),
        meta: { title: '资产' }
      },
      {
        path: '/deposit/:coinName',
        name: 'deposit',
        component: () => import('@/views/assets/deposit.vue'),
        meta: { title: '充值' }
      },
      {
        path: '/withdraw/:coin',
        name: 'withdraw',
        component: () => import('@/views/assets/withdraw.vue'),
        meta: { title: '提币' }
      },
      {
        path: '/withdraw-usdt',
        name: 'withdrawUsdt',
        component: () => import('@/views/assets/withdrawUsdt.vue'),
        meta: { title: '提币' }
      },
      {
        path: '/withdraw-art',
        name: 'withdrawArt',
        component: () => import('@/views/assets/withdrawArt.vue'),
        meta: { title: '提币' }
      },
      {
        path: '/wallet/records/:coin',
        name: 'walletRecords',
        component: () => import('@/views/assets/assetsRecords.vue'),
        meta: { title: '资金记录' }
      },
      {
        path: '/trade',
        name: 'trade',
        component: () => import('@/views/trade/trade.vue'),
        meta: { title: '交易' }
      },
      {
        path: '/convert/:amount',
        name: 'convert',
        component: () => import('@/views/trade/convert.vue'),
        meta: { title: '兑换' }
      },
      {
        path: '/convert-rt',
        name: 'convertRt',
        component: () => import('@/views/trade/convertRt.vue'),
        meta: { title: '兑换' }
      },
      {
        path: '/sell-rt',
        name: 'sellRt',
        component: () => import('@/views/trade/sellRt.vue'),
        meta: { title: 'RT 卖出 / ART 卖出' }
      },
      {
        path: '/art',
        name: 'Art',
        component: () => import('@/views/art/index.vue'),
        meta: { title: '生态' }
      },
      {
        path: '/art/token',
        name: 'ArtToken',
        component: () => import('@/views/art/istToken.vue'),
        meta: { title: 'ART通证' }
      },
      {
        path: '/art/token/records',
        name: 'ArtTokenRecords',
        component: () => import('@/views/art/tokenRecords.vue'),
        meta: { title: '参与记录' }
      },
      {
        path: '/art/partner',
        name: 'ArtPartner',
        component: () => import('@/views/art/partner.vue'),
        meta: { title: 'ART合伙人' }
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/profile/index.vue'),
        meta: { title: '我的' }
      },
      {
        path: '/referrer',
        name: 'Referrer',
        component: () => import('@/views/profile/referrer.vue'),
        meta: { title: '邀请好友' }
      },
      {
        path: '/art/partner/records',
        name: 'ArtPartnerRecords',
        component: () => import('@/views/art/partnerRecords.vue'),
        meta: { title: '参与记录' }
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/user/login.vue'),
    meta: { title: 'Login' }
  },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: () => import('@/views/user/register.vue'),
  //   meta: { title: 'Register' }
  // },
  // {
  //   path: '/forgot',
  //   name: 'Forgot',
  //   component: () => import('@/views/user/forgot.vue'),
  //   meta: { title: 'Forgot' }
  // },
  { path: '*', component: () => import("@/views/404.vue"), }
]

// 解决报错 NAVIGATION.
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes,
  mode: 'hash'
})


// 不需要登录页面 name
const LOGIN_PAGE_NAME = ['Login', 'Register', '404', 'Forgot'];

// 跳转之前
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (token) {
    next();
  } else {
    if (LOGIN_PAGE_NAME.includes(to.name)) {
      next();
    } else {
      next('/login');
    }
  }
  // next();
})
export default router
