<template>
  <div class="header card-bg flex justify-between align-center">
    <div class="left">
      <a-button
        v-if="!footerPath.includes($route.path)"
        class="back"
        type="link"
        icon="left"
        @click="onBack"
      ></a-button>
      
      <div class="small-logo" v-if="footerPath.includes($route.path)">
        <img src="@/assets/img/logo2.png" alt="">
      </div>
    </div>
    <div class="page-title" v-if="!$route.path.includes('/network/tree')">
      <span>{{ $t('Common.' + $route.meta.title) }}</span>
    </div>
    <div class="flex align-center">
      <a-button type="link" @click="$router.push('/art/token/records')" v-if="$route.path === '/art/token'">
        {{ this.$t('Common.参与记录') }}
      </a-button>
      
      <a-button type="link" @click="$router.push('/art/partner/records')" v-if="$route.path === '/art/partner'">
        {{ $t('Common.参与记录') }}
      </a-button>

      <a-select v-model="lang" :allowClear="false" @change="switchLang" v-if="footerPath.includes($route.path)">
        <a-select-option :value="key" v-for="(value, key) in langList" :key="key">
          {{ value }}
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script>
import FooterBar from './footerBar';
export default {  
  data() {
    return {
      lang: localStorage.getItem("lang") || "en-US",
      footerPath: FooterBar,
      langList: {
        'zh-CN': '中文',
        'en-US': 'English',
        'ja-JP': '日本語',
        'ko-KP': '한국어',
        'vi-VN': 'Vietnam',
      }
    };
  },
  computed: {
    getUserInfo() {
      return this.$store.state.UserInfo;
    },
    getUserName() {
      return this.getUserInfo.userName;
    },
    getUserLevel() {
      return this.getUserInfo.userLevel;
    },
    getLevelName() {
      let name = "";
      if (this.getUserLevel) {
        name = this.$t("level." + this.getUserLevel);
      }
      return name;
    },
  },
  methods: {
    onLogout() {
      localStorage.removeItem("token");
      this.$router.replace("/login");
    },
    switchLang(value) {
      localStorage.setItem("lang", value);
      this.lang = value;
      window.location.reload();
    },
    onBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ant-select-selection {
  border-radius: 99px !important;
}
.header {
  margin-bottom: 25px;
  position: relative;
  height: 60px;

  .back {
    line-height: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: bold;
  }

  .page-title {
    font-size: 20px;
    line-height: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .small-logo {
    height: 30px;
    img {
      height: 100%;
    }
  }
}



</style>
