<template>
  <div id="app">
    <a-spin :spinning="getLoading">
      <a-config-provider :locale="localData[locale]">
        <router-view />
      </a-config-provider>
    </a-spin>

    <a-modal title="提示" :visible="visible" :maskClosable="false" @ok="handleOk" @cancel="handleCancel">
      确认切换账户吗？
    </a-modal>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import enUS from 'ant-design-vue/lib/locale-provider/en_US';
import { webUserLogin, webUserLoginAuth } from "@/api";
export default {
  data() {
    return {
      locale: localStorage.getItem('lang') || 'en-US',
      localData: {
        'zh-CN': zhCN,
        'en-US': enUS
      },
      visible: false,
      addr: ''
    }
  },
  computed: {
    getLoading() {
      return this.$store.state.loading
    },
    webObj() {
      return this.$store.state.web3
    },
    tokenStatus() {
      return this.$store.state.tokenStatus
    }
  },
  watch: {
    tokenStatus: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (window.ethereum) {
            window.ethereum.on('accountsChanged', this.updateAccount)
          } 
        } else {
          if (window.ethereum) {
            window.ethereum.removeListener('accountsChanged', this.updateAccount)
          } 
        }
      }
    }
  },
  methods: {
    updateAccount(acc) {
      this.addr = acc[0];
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
    },
    handleOk() {
      this.getNonce(this.addr);
      this.visible = false;
    },
    async getNonce(address) {
      let params = {
        address,
        invitationCode: this.invitationCode
      }
      const res = await webUserLoginAuth(params);
      if (res.data.code === 20000) {
        // console.log('res :>> ', res);
        let nonce = res.data.data.nonce;
        this.webObj.eth.personal.sign(this.webObj.utils.fromUtf8(nonce), address, (err, res)=> {
          // console.log('res :>> ', res);
          let signature = res;
          localStorage.setItem('signature', signature);
          this.onLogin(address, signature);
        })

      } else {
        // this.$message.error(res.data.message);
      }
    }, 
    async onLogin(address, signature) {
      let params = {
        address,
        invitationCode: this.invitationCode,
        signature
      }

      const res = await webUserLogin(params);
      if (res.data.code === 20000) {
        // console.log('res.data :>> ', res.data);
        // 存储token
        let token = res.data.data.token;
        let bindId = res.data.data.bindId;
        localStorage.setItem("token", token);
        localStorage.setItem("bindId", bindId);
        this.$message.success('登录成功');
        this.onLoginSuccess();
      } else {
        // this.$message.error(res.data.message);
        this.btnLoading = false;
      }
    },
  },
  created() {
    this.$store.dispatch('storeActionWeb3');
    const token = localStorage.getItem('token');
    if (token) {
      this.$store.dispatch('storeActionUserInfo');
      this.$store.dispatch('storeActionAssetsList');
      this.$store.dispatch('storeActionInviteLink');
      this.$store.dispatch("storeSocketConnect");
      // this.$store.dispatch('storeActionGetCountryList');
    }
  }
}
</script>

<style>
@import url(./common/common.scss);

html {
  --primary: #201D36;
  --border: #545454;
}

#app {
  box-sizing: border-box;
  background: #fff;
  /* background: #201D36; */
  color: #fff;
}

div {
  box-sizing: border-box;
}

.ant-btn-link {
  padding: 0 !important;
  height: 24px !important;
}

.link-bg {
  padding: 10px 10px;
  background: linear-gradient(159deg, #2B1F46, #523F75);
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.39);
}


.btn-bg {
  height: 45px !important;
  background: linear-gradient(159deg, #2B1F46, #523F75) !important;
  border-radius: 99px !important;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.39) !important;
  width: 80%;
  font-size: 16px !important;
  letter-spacing: 3px !important;
}

.card-bg {
  padding: 15px;
  background: rgba(0,0,0,0.5);
  border-radius: 15px;
  word-break: break-all;
}

.ant-input {
  background-color: #2B1F46 !important;
  border-radius: 3px !important;
  border-color: #523F75 !important;
  height: 40px !important;
  color: #fff !important;
}

.ant-select-selection {
  background-color: #2B1F46 !important;
  border-radius: 3px !important;
  border-color: #523F75 !important;
}

.ant-select-arrow {
  color: #fff !important;
}
.ant-select-selection-selected-value {
  color: #fff !important;
}

</style>
