<template>
  
  <PullRefresh :onRefresh="onRefresh" :config="refreshConfig" :pullStatus="getPullStatus">
    <div id="init" :class="footerPath.includes($route.path) ? 'footer-page' : ''">
      <VHeader></VHeader>

      <div class="init-view">
        <RouterView></RouterView>
      </div>

      <VFooter v-if="footerPath.includes($route.path)"></VFooter>
    </div>
  </PullRefresh>
</template>

<script>
import VNavbar from "./navbar.vue";
import VFooter from "./footer.vue";
import VHeader from "./header.vue";
import Assets from "@/components/assets.vue";
import { RouterView } from "vue-router";
import FooterBar from './footerBar';
import PullRefresh from '@/components/Pull-refresh/index.vue';
export default {
  components: {
    VNavbar,
    VFooter,
    VHeader,
    Assets,
    PullRefresh
  },
  data() {
    return {
      collapsed: true,
      hide: window.innerWidth > 576 ? true : false,
      visible: false,
      footerPath: FooterBar,
      refreshConfig: {
        errorLabel: this.$t('Common.加载中'),
        startLabel: this.$t('Common.加载中'),
        readyLabel: this.$t('Common.加载中'),
        loadingLabel: this.$t('Common.加载中')
      },
      pullPage: [
        ...FooterBar,
        '/wallet/records',
        '/art/token/records',
        '/art/token',
        '/art/partner/records',
      ]
    };
  },
  computed: {
    getPageTitle() {
      return this.$store.state.pageTitle;
    },
    getPullStatus() {
      let res = true;
      let current = this.$route.path;
      res = this.pullPage.includes(current);
      if(!res) {
        if(current.includes('/wallet/records')) {
          res = true;
        }
      }
      return res;
    }
  },
  methods: {
    onCollapse(collapsed, type) {
      // // console.log(collapsed, type);
    },
    onBreakpoint(broken) {
      // // console.log(broken);
    },
    onMouseEnter() {
      this.collapsed = false;
    },
    onMouseLeave() {
      this.collapsed = true;
    },
    showNavBar() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    onBack() {
      this.$router.back();
    },
    onRefresh() {
      this.$myEvent.$emit('refresh');
      return new Promise((resolve, reject)=> {
        setTimeout(()=> {
          resolve();
        }, 0.5 * 1000)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
#init {
  width: 500px;
  min-height: 100vh;
  padding: 15px 15px 0;
  margin: 0 auto;

  background: url(../assets/img/bg.png);
  background-size: 100% auto;

  &.footer-page {
    padding-bottom: 90px;
  }
}

.menu {
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}



::v-deep .ant-drawer-body {
  padding: 15px 0;
}

::v-deep .ant-drawer-content {
  padding: 0 15px;
  background: #545454;
}
::v-deep .ant-drawer-content-wrapper {
  border-radius: 0 0 45px 0;
  overflow: hidden;
}


@media screen and (max-width: 576px) {
  #init {
    width: 100%;
  }
}
</style>
