import service from "@/http/httpService";

// 参与挖矿信息
export const getMiningInfo = (params) => service.post('/api/app/ist-pool/joinMiningInfo', { data: params, needEn: true, notLoading: true })
// 参与时间信息
export const getMiningTime = (params) => service.post('/api/app/ist-pool/joinTimeInfo', { data: params, needEn: true, notLoading: true })
// App查询参与挖矿记录
export const getMiningRecords = (params) => service.post('/api/app/ist-pool/istPoolRecordList', { data: params, needEn: true })
// 参与挖矿
export const joinMining = (params) => service.post('/api/app/ist-pool/joinMining', { data: params, needEn: true })
// ART资金池图表统计
export const getCapitalPoolChartList = (params) => service.post('/api/app/assets/istPoolChartList', { data: params, needEn: true })


// 购买超级节点
export const buySuperNode = (params) => service.post('/api/app/super-node/buySuperNode', { data: params, needEn: true })
// 领取超级节点分红
export const receiveSuperNodeBonus = (params) => service.post('/api/app/super-node/receiveSuperNodeBonus', { data: params, needEn: true })
// 查询超级节点分红信息
export const getSuperNodeBonusInfo = (params) => service.post('/api/app/super-node/superNodeBonusInfo', { data: params, needEn: true, notLoading: true })
// 购买超级节点记录
export const getSuperNodeRecordList = (params) => service.post('/api/app/super-node/superNodeRecordList', { data: params, needEn: true })

// 挖矿记录
export const getTokenRecords = (params) => service.post('/api/app/ist-pool/istPoolAssetsRecordList', { data: params, needEn: true })
// 购买超级节点记录
export const getPartnerRecords = (params) => service.post('/api/app/ist-pool/istPoolAssetsRecordList', { data: params, needEn: true })
