/**
 *  IP 信息
 */
let baseUrl, imageUrl, socketUrl, hashOrigin, pdfUrl;

// const origin = 'http://217.79.252.98:24500';
const origin = 'https://artistubt.io';

switch (process.env.NODE_ENV) {
  case "development":

    baseUrl = origin + "/art";
    imageUrl = origin + "/art/";
    socketUrl = 'wss://artistubt.io/ez';
    break;
  case "production":

    baseUrl = "https://artistubt.io/art";
    imageUrl = "https://artistubt.io/art/";
    socketUrl = 'wss://artistubt.io/ez'
    break;
}

export { baseUrl, imageUrl, socketUrl };
